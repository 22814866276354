
    import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
    import ItemPane from './ItemPane.vue';
    import NodeData from './NodeData';
    import ItemData from './ItemData';
    import ItemBox from './ItemBox';

    @Component({
        components: {
            ItemPane,
        },
    })
    export default class NodePane extends Vue {
        private isOpen: boolean = false;

        @Prop({
            type: NodeData,
            required: false,
            default: () => (new NodeData()),
        })
        private data!: NodeData;

        @Prop({
            type: ItemBox,
            required: false,
            default: () => (new ItemBox()),
        })
        private box!: ItemBox;

        public onClick() {
            if (this.data) {
                this.data.isOpen = !this.data.isOpen;
            }
        }

        public contextMenu(e: MouseEvent) {
            if (this.data) {
                this.onContextMenu(e, this.data);
            }
        }

        @Emit('on-context-menu')
        private onContextMenu(e: MouseEvent, data: NodeData) {
            // 菜单
        }

        @Emit('on-item-selected')
        private onItemSelected(data: ItemData) {
            // 选中
        }

        @Emit('on-item-context-menu')
        private onItemContextMenu(e: MouseEvent, data: ItemData) {
            // 选中
        }
    }
