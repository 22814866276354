
import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import mainViewData from '@/platform/wap/view/data/MainViewData';
import mainBaseTabs from '@/platform/wap/view/data/MainBaseTabs';
import MessageListPane from '@/views/module/message/MessageListPane.vue';
import ContactListNavPane from '@/views/main/list/ContactListNavPane.vue';
import ProfilePane from '@/views/main/me/ProfilePane.vue';

import app from '@/app/App';
import MessageAllUnreadView from '@/app/com/main/view/MessageAllUnreadView';
import mainActiveTab from '@/views/main/MainActive';
import Client from '@/app/base/message/client/Client';
import MainView from '@/app/com/client/common/view/MainView';
import WorkViewEnum from '@/app/com/common/view/WorkViewEnum';
import AppUtil from '@/platform/wap/util/AppUtil';


@Component({
    components: {
        MessageListPane,
        ContactListNavPane,
        ProfilePane,
    },
})
export default class Main extends Vue implements MainView {
    private data = mainViewData;
    private tabs = mainBaseTabs;

    // 声明周期钩子
    public mounted() {
        app.appContext.putViewObject(WorkViewEnum.MainView, this);
        mainViewData.initialize();
    }

    public showOtherOnline(offline: boolean, client: Client): void {
        if (offline) {
            AppUtil.logout();
            app.prompt('您的账号在其他地方登录！');
        }
    }
}
