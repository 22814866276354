
import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';

import MessageRefreshPane from '@/views/module/message/MessageRefreshPane.vue';

import IconItem from '@/views/common/list/IconItem.vue';
import IconListPane from '../../common/list/IconListPane.vue';
import IconItemData from '../../common/list/IconItemData';
import messageListModel from '@/platform/vue/view/model/MessageListModel';
import IconItemBox from '@/views/common/list/IconItemBox';
import CommonIcon from '@/platform/common/web/CommonIcon';


@Component({
    components: { MessageRefreshPane, IconItem },
})
export default class MessageListPane extends Vue {
    private isLoading: boolean = false;
    private items: IconItemData[] = messageListModel.list;
    private box: IconItemBox = messageListModel.box;
    private hasMessage = true;
    private noLogo = CommonIcon.noLogo;

    public mounted() {
        // console.log('消息列表', messageListModel)
        // console.log('消息列表', messageListModel.list)

    }

    public loadUnread() {

    }

    public handleTopChange() {

    }

    private onRefresh() {
        setTimeout(() => {
            this.isLoading = false;
        }, 1000);
    }

    private onSelected(data: IconItemData) {
        this.$router.push({ path: '/chat.user' });
    }

    private selected(data: IconItemData) {
        // no
    }

    private onItemContextMenu(data: IconItemData) {
        // 选中
    }
}
