
import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import IconItemData from '@/views/common/list/IconItemData';
import IconItemBox from '@/views/common/list/IconItemBox';

@Component({})
export default class IconItem extends Vue {
    @Prop({
        type: IconItemData,
        required: false,
        default: () => (new IconItemData()),
    })
    private data!: IconItemData;

    @Prop({
        type: IconItemBox,
        required: false,
        default: () => (new IconItemBox()),
    })
    private box!: IconItemBox;

    public onClick() {
        if (this.box && this.data) {
            this.box.select(this.data);
        }
        // console.log('onClick', this.data);
        if (this.data) {
            this.selected(this.data);
            if (typeof this.data.onSelect === 'function') {
                this.data.onSelect(this.data.key, this.data.getData());
            }
        }
    }

    private onDelete() {
        if (this.data) {
            if (typeof this.data.onDelete === 'function') {
                this.data.onDelete(this.data.key, this.data.getData());
            }
        }
    }

    private contextMenu(e: Event) {
        if (this.data) {
            this.onContextMenu(e, this.data);
        }
    }

    @Emit('on-selected')
    private selected(data: IconItemData) {
        // console.log('selected')
        // 选中
    }

    @Emit('on-context-menu')
    private onContextMenu(e: Event, data: IconItemData) {
        // 菜单
    }
}
