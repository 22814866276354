
  import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';

  @Component({
    components: {},
  })
  export default class MessageRefreshPane extends Vue {
    @Prop({
      type: String,
      required: false,
      default: () => ('pull'),
    })
    private status!: string;
  }
