
import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';

import personalViewModel from '@/platform/vue/view/model/PersonalViewModel';
import PersonalData from '@/views/common/data/PersonalData';
import AppUtil from '@/platform/wap/util/AppUtil';
import RouterUtil from '@/common/vue/RouterUtil';


@Component({
    components: {},
})
export default class ProfilePane extends Vue {
    private personalData: PersonalData = personalViewModel.personalData;

    private exit(): void {
        AppUtil.logout();
    }

    private personal() {
        RouterUtil.toByPath('/personal.info');
    }

    private updatePassword() {
        RouterUtil.toByPath('/personal.update.password');
    }
}
