
import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import contactNavActive from '@/views/main/list/ContactNavActive';

import ContactAllListPane from '@/views/main/list/ContactAllListPane.vue';
import UserListPane from '@/views/main/list/ContactListPane.vue';
import GroupListPane from '@/views/main/list/GroupListPane.vue';

@Component({
    components: {
        ContactAllListPane,
        UserListPane,
        GroupListPane,
    },
})
export default class ContactListNavPane extends Vue {
    private contactNav = contactNavActive;

    public mounted() {
        // no
    }

    public findUser() {
        this.$router.push({ path: '/user.find' });
    }

    public findGroup() {
        this.$router.push({ path: '/group.find' });
    }

    private noticeContactAddApplyList() {
        const path = '/notice.contact.add.apply.list';
        this.$router.push({ path: path });
    }

    private noticeGroupHandle() {
        const path = '/notice.group.handle';
        this.$router.push({ path: path });
    }
}
